// src/ChatBot.scss

$primary-color: #007bff;
$secondary-color: #f1f1f1;
$border-color: #ccc;
$background-color: #fff;
$border-radius: 50%;

.chat-bot-main {
  background-color: $background-color;
  margin: auto;
  margin-top: 0px;
  //width: 95%;
}

.chatbot-container {
  display: flex;
  flex-direction: column;
  //width:100%;
  background-color: $background-color;
  overflow: unset;
  // min-height: 100vh;
  height: 78vh;

}

.chatbot-messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  height: 90vh;
  flex-direction: column;
  gap: 10px;
}

.message {
  padding: 10px 15px;
  border-radius: 10px;
  word-wrap: break-word;
  &.user {
    align-self: flex-end;
    background-color: $primary-color;
    color: #333333;
    max-width: 50%;
    text-align: left;
  }

  &.bot {
    align-self: flex-start;
    color: black;
    max-width: 90%;
    text-align: justify;
    &:hover {
      .message-options-container{
        visibility: visible;
      }
    }
  }
}

.chat-bot-input-wrapper {
  //width: 65%;
  background-color: $background-color;
  height: auto;
  position: fixed;
  bottom: 0px;
}

.chatbot-input {
  display: flex;
  border: none;
  background-color: $secondary-color;
  border-radius: 25px;
  position: relative;
  bottom: 20px;

  input {
    flex: 1;
    padding: 15px;
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 25px;
    width: auto;
    text-indent: 20px;
    margin: auto;
    background-color: $secondary-color;
  }

  button {
    scale: 0.8;
    padding: 0 20px;
    border: none;
    background-color: $primary-color;
    color: $secondary-color;
    font-size: 16px;
    cursor: pointer;
    border-radius: $border-radius;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
    &:disabled{
      background-color: rgba($color: $primary-color, $alpha: 0.2);
    }
  }
}
.active-chat{
  background-color: #fff !important;
  .active-chat-options{
    display: block !important;
  }
}

.active-chat-options {
  display: none;
}


.icon-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #ccc;
  img{
    width: inherit;
    height: inherit;
    border-radius: inherit;
    margin-top: 0px;
  }
}

.menu-bar {
  //width: 230px;
  padding: 15px;
  background-color: #f5f5f5;
  height: 78vh;
  overflow-y: auto;
  border-radius: 0px 0px 15px 15px;


  h3 {
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding: 10px;
      cursor: pointer;
      border-radius: 8px;
      font-weight: 500;
      .chat-name{
        white-space: nowrap;
        width: 90%;
        overflow-x: hidden;
        text-overflow: ellipsis !important;
        color: #333 !important;
      }
      &:hover {
        background-color: lighten($secondary-color, 10%);
        .active-chat-options{
          display: block !important;
        }
      }
    }
  }
}

.menu-buttons{
  background-color: #f5f5f5;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 5px;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
}

.menu-toggle-button {
  background-color: transparent;
  border: none;
  padding: 0;
  position: fixed;
  cursor: pointer;
}

.new-chat-button {
  // background-color: #fff;
  // background-color: #f5f5f5;
  border: none;
  padding: 7px 7px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #7A70BA;
  background-color: rgba(122, 112, 186, 0.1) !important;
  color: #333 !important;
  &:hover {
    background-color: #7A70BA !important;
    color: #fff !important;
    .fa-plus {
      color: #fff;
    }
  }
}

.active-button {
   background-color: #7A70BA !important;
    color: #fff !important;
    .fa-plus {
      color: #fff !important;
    }
}



.new-chat-button .fa-plus {
  width: 1.5rem;
  color: #333;
}

.new-chat-button span {
  font-weight: bold;
  margin-left: 10px;
}

/* src/NewChatForm.css */
.new-chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full height of the viewport */
  background-color: #f5f5f5;
  /* Optional: background color */
}

.cards-wrapper {
  display: flex;
  justify-content: space-around;
  width: 80%;
}


.new-chat {
  height: inherit;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, .15);
  img{
    opacity: 0.8;
    position: absolute;
    scale: 2.5 ;
  }
}
.message-options-container{
  visibility: hidden ;
}

.message-options {
  padding: 1px 3px;
  border-radius: 3px;
  &:hover {
    background-color: rgba($color: #7a70ba, $alpha: 0.9);
    scale: 1.2;
  }

}

.margin-left-6 {
  margin-left: 6px;
}
.button-options {
  button {
    &:hover {
      .color-white {
        color: #fff !important;
      }
    }
  }
  .custom-fileupload {
    border-radius: 2rem ;
    background-color: transparent;
    border: 1px solid;
    color: #12225f;
  }
}


.p-button .p-button-label {
  font-weight: 400 !important;
  margin-left: 5px !important;
  font-size: 1rem !important;
}
.position-relative {
  position: relative;
}

.messages-loader {
  .load__icon-wrap{
    .load__icon {
      margin-left: 236px ;
    }
  }
}

.questions {
  padding: 10px 15px;
  border-radius: 10px;
  background-color:  #7a70ba;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 1360px) {
  .chat-bot-input-wrapper{
    //width: 51%;
  }
}

@media screen and (max-width: 900px) {
  .menubar {
    display: none !important;
  }
}
